import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoteIcon from '@material-ui/icons/Note';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleChecklist from '../AxleChecklist';
import AxleInput from '../AxleInput';
import AxleSelect from '../AxleSelect';

const useStyles = makeStyles(theme => ({
    label: {
        textAlign: 'left',
        '& p, h2, h1': {
            margin: theme.spacing(1, 0),
        },
    },
}));

// TODO: If we allow clients to set their own questions, need to prevent XSS when setting HTML
export default function QuestionContainer({ questions, handleQuestionResponse, errors }) {
    const classes = useStyles();
    const renderInput = ({ id, title, is_required, value, multiline, error }) => {
        return (
            <AxleInput
                value={value}
                variant='outlined'
                placeholder=''
                customLabel={
                    <Box className={classes.label}>
                        <Typography variant='body1'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${title}${is_required ? ' (required)' : ''}`,
                                }}
                            />
                        </Typography>
                    </Box>
                }
                onChange={e =>
                    handleQuestionResponse({
                        value: e.target ? e.target.value : '',
                        questionId: id,
                    })
                }
                required={is_required}
                multiline={multiline}
                rows={multiline ? 4 : 1}
                error={error}
            />
        );
    };

    const renderDropdown = ({ id, title, is_required, value, options, error }) => {
        return (
            <AxleSelect
                customLabel={
                    <Box className={classes.label}>
                        <Typography variant='body1'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${title}${is_required ? ' (required)' : ''}`,
                                }}
                            />
                        </Typography>
                    </Box>
                }
                value={value}
                required={is_required}
                onChange={e =>
                    handleQuestionResponse({
                        value: e.target ? e.target.value : '',
                        questionId: id,
                    })
                }
                error={error}
            >
                {options.map((x, i) => (
                    <MenuItem value={x} key={i}>
                        {x}
                    </MenuItem>
                ))}
            </AxleSelect>
        );
    };

    const renderCheckbox = ({ id, title, is_required, value, error }) => {
        return (
            <Grid item md={12} style={{ textAlign: 'left' }}>
                <FormControl error={error ? true : false}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value}
                                color='primary'
                                onChange={e =>
                                    handleQuestionResponse({
                                        value: e.target.checked,
                                        questionId: id,
                                    })
                                }
                            />
                        }
                        label={
                            <Box className={classes.label}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: `${title}${is_required ? ' (required)' : ''}`,
                                    }}
                                />
                            </Box>
                        }
                    />
                    {error && <FormHelperText variant='outlined'>{error}</FormHelperText>}
                </FormControl>
            </Grid>
        );
    };

    const renderMultiselect = ({ id, title, is_required, options, error }) => {
        return (
            <AxleChecklist
                options={options}
                error={error}
                customLabel={
                    <Box className={classes.label}>
                        <Typography variant='body1'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${title}${is_required ? ' (required)' : ''}`,
                                }}
                            />
                        </Typography>
                    </Box>
                }
                handleQuestionResponse={handleQuestionResponse}
                questionId={id}
            />
        );
    };

    const renderQuestionType = question => {
        const { id, question_type, options, title, is_required, value } = question;
        const error = errors[`${id}`];
        switch (question_type) {
            case 'textfield':
                return renderInput({ id, title, is_required, value, error });
            case 'multiline':
                return renderInput({
                    id,
                    title,
                    is_required,
                    value,
                    multiline: true,
                    error,
                });
            case 'checkbox':
                return renderCheckbox({ id, title, is_required, value, error });
            case 'dropdown':
                return renderDropdown({
                    id,
                    title,
                    is_required,
                    value,
                    options,
                    error,
                });
            case 'number':
                return renderInput({ id, title, is_required, value, error });
            case 'multiselect':
                return renderMultiselect({
                    id,
                    title,
                    is_required,
                    options,
                    error,
                });
            default:
                return null;
        }
    };

    return (
        <AxleAccordionContainer
            title='Intake form'
            icon={<NoteIcon fontSize='small' color='primary' />}
            subTitle='A few questions before your visit'
        >
            <Grid container spacing={2}>
                {questions.map((q, i) => {
                    return (
                        <Grid item md={12} key={i}>
                            {renderQuestionType(q)}
                        </Grid>
                    );
                })}
            </Grid>
        </AxleAccordionContainer>
    );
}
