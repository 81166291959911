import { AppBar, CircularProgress, Toolbar } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import './App.css';
import {
    setPartnerId,
    setPartnerMeta,
    setPresetServices,
    setProviderId,
    setVisitRequestId,
    setZipcode,
} from './actions';
import { fetchPartnerMeta } from './api';
import LogoRightAligned from './assets/logoRightAligned.png';
import { routesWithoutNav } from './constants';
import {
    Book,
    Confirmation,
    Document,
    Location,
    NotFound,
    Nps,
    Review,
    StandaloneIntakeForm,
    Waitlist,
} from './pages';

class App extends Component {
    state = {
        initialLoading: true,
    };

    async componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        if (params && params.partner_id) {
            this.props.setPartnerId(params.partner_id);
            const { result } = await fetchPartnerMeta(params.partner_id);
            if (result) {
                this.props.setPartnerMeta(result);
            }
        } else {
            this.props.setPartnerId(undefined);
        }

        if (params && params.visit_request_id) {
            this.props.setVisitRequestId(params.visit_request_id);
        } else {
            this.props.setVisitRequestId(undefined);
        }

        if (params && params.preset_services) {
            this.props.setPresetServices(params.preset_services);
        } else {
            this.props.setPresetServices(undefined);
        }

        if (params && params.provider_id) {
            this.props.setProviderId(params.provider_id);
        }

        if (params && params.zip_code) {
            this.props.setZipcode(params.zip_code);
        }

        this.setState({
            initialLoading: false,
        });
    }

    shouldRenderNavbar = () => {
        const params = queryString.parse(this.props.location.search);
        if (params && params.embed == 'true') {
            return false;
        }
        for (const path of routesWithoutNav) {
            if (this.props.location.pathname.indexOf(path) !== -1) {
                return false;
            }
        }
        return true;
    };

    getLogo = () => {
        return this.props.partnerMeta && this.props.partnerMeta.logo_url
            ? this.props.partnerMeta.logo_url
            : LogoRightAligned;
    };

    buildMuiTheme = () => {
        return createMuiTheme({
            palette: {
                type: 'light',
                primary: {
                    main:
                        this.props.partnerMeta && this.props.partnerMeta.theme_color
                            ? this.props.partnerMeta.theme_color
                            : '#54BBBD',
                    contrastText: '#fff',
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                },
            },
        });
    };

    render() {
        return (
            <MuiThemeProvider theme={this.buildMuiTheme()}>
                <div className='App'>
                    {this.state.initialLoading ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {this.shouldRenderNavbar() && (
                                <AppBar position='static' style={{ background: '#fff' }}>
                                    <Toolbar>
                                        <img
                                            src={this.getLogo()}
                                            style={{
                                                maxWidth: '256px',
                                                maxHeight: '48px',
                                            }}
                                        />
                                    </Toolbar>
                                </AppBar>
                            )}
                            <Switch>
                                {/* SO MANY OF THESE ENDPOINTS DON'T EVEN WORK ANYMORE, DELETE THEM LOL (like `/availability`). */}
                                <Route exact path='/' render={() => <Redirect to='/location' />} />
                                <Route exact path='/location' component={Location} />
                                <Route exact path='/book' component={Book} />
                                <Route exact path='/waitlist' component={Waitlist} />
                                <Route
                                    exact
                                    path='/confirmation/:visitId'
                                    component={Confirmation}
                                />
                                <Route exact path='/review/:visitId' component={Review} />
                                <Route exact path='/nps/:visitId' component={Nps} />
                                <Route
                                    exact
                                    path='/intake-form/:visitId'
                                    component={StandaloneIntakeForm}
                                />
                                <Route exact path='/docs/:docId' component={Document} />
                                <Route component={NotFound} />
                            </Switch>
                        </>
                    )}
                </div>
            </MuiThemeProvider>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setPartnerMeta: val => dispatch(setPartnerMeta(val)),
    setPartnerId: val => dispatch(setPartnerId(val)),
    setPresetServices: val => dispatch(setPresetServices(val)),
    setProviderId: val => dispatch(setProviderId(val)),
    setVisitRequestId: val => dispatch(setVisitRequestId(val)),
    setZipcode: val => dispatch(setZipcode(val)),
});

const mapStateToProps = state => ({
    partnerMeta: state.partnerMeta,
});

const AppWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

class AppWrapper extends React.Component {
    render() {
        return (
            // <MuiThemeProvider theme={AxleTheme}>
            <Router>
                <AppWithRouter />
            </Router>
            // </MuiThemeProvider>
        );
    }
}

export default AppWrapper;
