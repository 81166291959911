import { camelCase, isArray, isObject, map } from 'lodash';

export const keysCamelToSnake = obj => {
    const snakeObj = {};
    for (const k of Object.keys(obj)) {
        const snakeKey = k.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        snakeObj[snakeKey] = obj[k];
    }
    return snakeObj;
};

export const keysSnakeToCamel = obj => {
    if (isArray(obj)) {
        return map(obj, keysSnakeToCamel);
    }

    if (isObject(obj)) {
        return _(obj)
            .mapKeys((v, k) => camelCase(k))
            .mapValues((v, k) => keysSnakeToCamel(v))
            .value();
    }

    return obj;
};

export const getDatetimeFromString = value => {
    if (!value) return new Date();
    try {
        const parts = value.split(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})-(.*)/gm);
        const year = parts[1];
        const month = parts[2];
        const day = parts[3];
        const hour = parts[4];
        const minute = parts[5];
        const second = parts[6];

        return new Date(year, month, day, hour, minute, second);
    } catch (error) {
        return new Date();
    }
};

export const validateEmail = email => {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
};

export const validatePhone = phone => {
    var regex = /^[2-9]\d{9}$/;
    var digits = phone.replace(/\D/g, '');
    return regex.test(digits);
};
